"use client"
import { ComponentProps, ReactNode } from "react"
import { useIsMobile } from "../../hooks"
import * as CustomDialog from "../Dialog/Dialog"
import * as CustomDrawer from "../Drawer/Drawer"

const ResponsiveModal = (props: {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children: ReactNode
}) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.Drawer {...props} />
  ) : (
    <CustomDialog.Dialog {...props} />
  )
}

const ResponsiveModalTrigger = (
  props: ComponentProps<typeof CustomDrawer.DrawerTrigger>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerTrigger {...props} />
  ) : (
    <CustomDialog.DialogTrigger {...props} />
  )
}

const ResponsiveModalPortal = (
  props: ComponentProps<typeof CustomDrawer.DrawerPortal>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerPortal {...props} />
  ) : (
    <CustomDialog.DialogPortal {...props} />
  )
}

const ResponsiveModalClose = (
  props: ComponentProps<typeof CustomDrawer.DrawerClose>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerClose {...props} />
  ) : (
    <CustomDialog.DialogClose {...props} />
  )
}

const ResponsiveModalOverlay = (
  props: ComponentProps<typeof CustomDrawer.DrawerOverlay>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerOverlay {...props} />
  ) : (
    <CustomDialog.DialogOverlay {...props} />
  )
}

const ResponsiveModalContent = (
  props: ComponentProps<typeof CustomDrawer.DrawerContent> &
    Pick<ComponentProps<typeof CustomDialog.DialogContent>, "withCloseButton">,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerContent {...props} />
  ) : (
    <CustomDialog.DialogContent {...props} />
  )
}

const ResponsiveModalHeader = (
  props: ComponentProps<typeof CustomDrawer.DrawerHeader>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerHeader {...props} />
  ) : (
    <CustomDialog.DialogHeader {...props} />
  )
}

const ResponsiveModalFooter = (
  props: ComponentProps<typeof CustomDrawer.DrawerFooter>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerFooter {...props} />
  ) : (
    <CustomDialog.DialogFooter {...props} />
  )
}

const ResponsiveModalTitle = (
  props: ComponentProps<typeof CustomDrawer.DrawerTitle>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerTitle {...props} />
  ) : (
    <CustomDialog.DialogTitle {...props} />
  )
}

const ResponsiveModalDescription = (
  props: ComponentProps<typeof CustomDrawer.DrawerDescription>,
) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <CustomDrawer.DrawerDescription {...props} />
  ) : (
    <CustomDialog.DialogDescription {...props} />
  )
}

export {
  ResponsiveModal,
  ResponsiveModalTrigger,
  ResponsiveModalPortal,
  ResponsiveModalClose,
  ResponsiveModalOverlay,
  ResponsiveModalContent,
  ResponsiveModalHeader,
  ResponsiveModalFooter,
  ResponsiveModalTitle,
  ResponsiveModalDescription,
}
