"use client"

import { create } from "zustand"
import { LaunchModalContentProps } from "../components"
import { BaseModalStoreType } from "@store-platform/types"

export const useLaunchModal = create<
  BaseModalStoreType<LaunchModalContentProps>
>((set) => ({
  payload: { domainOwner: false, launchLinks: [], iconUrl: "" },
  isOpen: false,
  open: (payload) => set({ isOpen: true, payload }),
  onOpenChange: (open: boolean) => set({ isOpen: open }),
}))
