"use client"

import { useEffect, useState } from "react"
import Cropper from "react-easy-crop"
import { Slider } from "../FormFieldSlider/Slider"
import { Button } from "../Button/Button"

type Area = {
  width: number
  height: number
  x: number
  y: number
}

export type ImageCropperProps = {
  image: File
  aspectRatio?: number
  shape?: "rect" | "round"
} & (
  | { outputFormat: "base64"; onSubmit: (file: string) => unknown }
  | { outputFormat: "file"; onSubmit: (file: File) => unknown }
)

export function ImageCropper(props: ImageCropperProps) {
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)

  useEffect(() => {
    const reader = new FileReader()
    reader.addEventListener("load", () => {
      if (typeof reader.result === "string") {
        setImageSrc(reader.result)
      }
    })
    reader.readAsDataURL(props.image)
  }, [props.image])

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const getCroppedImg = async () => {
    if (!imageSrc || !croppedAreaPixels) return null

    const image = new Image()
    image.src = imageSrc

    const maxSize = Math.max(image.width, image.height)
    const canvas = new OffscreenCanvas(maxSize, maxSize)
    const ctx = canvas.getContext("2d")
    if (!ctx) return null

    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
    canvas.width = safeArea
    canvas.height = safeArea
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate((rotation * Math.PI) / 180)
    ctx.translate(-safeArea / 2, -safeArea / 2)
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)
    canvas.width = croppedAreaPixels.width
    canvas.height = croppedAreaPixels.height
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - croppedAreaPixels.x,
      0 - safeArea / 2 + image.height * 0.5 - croppedAreaPixels.y,
    )

    canvas.convertToBlob({ type: "image/png", quality: 0.8 }).then((blob) => {
      if (!blob) return null
      const file = new File([blob], props.image.name, {
        type: "image/png",
        lastModified: Date.now(),
      })
      if (props.outputFormat === "base64") {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const base64data = reader.result
          if (typeof base64data === "string") {
            props.onSubmit(base64data)
          }
        }
      } else {
        props.onSubmit(file)
      }
    })
  }

  if (!imageSrc) return null

  return (
    <>
      <div data-vaul-no-drag={true} className="relative h-[500px]">
        <Cropper
          image={imageSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={props.aspectRatio || 1}
          cropShape={props.shape}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Slider
        data-vaul-no-drag
        action={setZoom}
        min={1}
        max={3}
        step={0.1}
        label="Zoom"
        className="flex w-full p-2 gap-6"
        value={zoom}
      />
      <Button variant="default" onClick={getCroppedImg}>
        Submit
      </Button>
    </>
  )
}
