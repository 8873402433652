"use client"
import {
  SvgAlertTriangle,
  SvgAndroid,
  SvgArrowUpRight,
  SvgBrowser,
  SvgDevices,
  SvgDownload,
  SvgIos,
  SvgMacOs,
  SvgPwa,
} from "@store-platform/ui/icons"
import { InstallInstructions } from "../misc/InstallInstructions"
import { Link as TypeLink, LinkPlatform, LinkType } from "@store-platform/types"
import { Link } from "../Link/Link"
import { orderBy } from "lodash-es"
import { AppIcon } from "../AppIcon/AppIcon"

export type LaunchModalContentProps = {
  domainOwner: boolean
  launchLinks: TypeLink[]
  pwa?: boolean
  launchTracker?: string
  listingId?: string
  iconUrl?: string
  appName?: string
}

const platformOrder: LinkPlatform[] = [
  "web_app",
  "website",
  "ios",
  "android",
  "macos",
]
const typeOrder: LinkType[] = ["install", "direct_install"]
const getOrderIndex = (item: string, order: string[]) => {
  const index = order.indexOf(item)
  return index === -1 ? order.length : index
}

export const LaunchModalContent = (props: LaunchModalContentProps) => {
  if (props.launchLinks.length === 0) return null

  const sortedLinks = orderBy(props.launchLinks, [
    (obj) => getOrderIndex(obj.platform, platformOrder),
    (obj) => getOrderIndex(obj.type, typeOrder),
  ])

  return (
    <div className="flex flex-col gap-y-2 min-w-[375px]">
      <div className="grid grid-rows-[3.5rem_2.5rem] grid-cols-[1fr] items-center justify-items-center">
        <AppIcon
          imgUrl={props.iconUrl}
          size={256}
          name={props.appName}
          className="h-32 w-32 -translate-y-[calc(4rem-12px)] border"
          transparentBg={false}
        />
        <p className="text-2xl font-semibold">{props.appName}</p>
      </div>
      {sortedLinks.map((link, index) => (
        <Launch key={index} link={link} {...props} />
      ))}
    </div>
  )
}

const getAction = (platform: LinkPlatform, pwa: boolean) => {
  if (platform === "web_app" || platform === "website") {
    return pwa ? "Launch PWA" : "Launch Web App"
  }
  if (platform === "ios") {
    return "Install iOS App"
  }
  if (platform === "android") {
    return "Install Android App"
  }
  if (platform === "macos") {
    return "Install MacOS App"
  }
  return "Launch App"
}

const getDescription = (
  platform: LinkPlatform,
  pwa: boolean,
  type: LinkType,
  domainOwner: boolean,
) => {
  if (platform === "web_app" || platform === "website") {
    if (!domainOwner) {
      return (
        <span className="flex items-end gap-0.5 text-orange-600">
          <SvgAlertTriangle className="h-4 w-4" /> Domain ownership not verified
        </span>
      )
    }

    return pwa
      ? "Install the app on your device"
      : "Launch the app in your browser"
  }
  if (platform === "ios" || platform === "macos") {
    return type === "direct_install"
      ? "This will start the app download"
      : "Download from the App Store"
  }
  if (platform === "android") {
    return type === "direct_install"
      ? "This will start the app download"
      : "Download from the Play Store"
  }
  return "Launch the app"
}

const getActionIcon = (platform: LinkPlatform, pwa: boolean) => {
  if (platform === "web_app" || platform === "website") {
    return pwa ? SvgPwa : SvgBrowser
  }
  if (platform === "ios") {
    return SvgIos
  }
  if (platform === "macos") {
    return SvgMacOs
  }
  if (platform === "android") {
    return SvgAndroid
  }
  return SvgDevices
}

const getLaunchIcon = (type: LinkType) => {
  if (type === "direct_install") {
    return SvgDownload
  }
  return SvgArrowUpRight
}

const Launch = (
  props: Omit<LaunchModalContentProps, "launchLinks"> & {
    link: TypeLink
  },
) => {
  if (!props.link?.src) return null
  if (!["android", "macos", "ios", "web_app"].includes(props.link.platform))
    return null

  const url = new URL(props.link.src)
  url.searchParams.set("utm_source", "store.app")

  const action = getAction(props.link.platform, !!props.pwa)
  const description = getDescription(
    props.link.platform,
    !!props.pwa,
    props.link.type,
    props.domainOwner,
  )
  const ActionIcon = getActionIcon(props.link.platform, !!props.pwa)
  const LaunchIcon = getLaunchIcon(props.link.type)

  return (
    <>
      {props.link.platform === "web_app" && props.pwa && (
        <InstallInstructions />
      )}
      <Link
        variant="naked"
        href={url.toString()}
        target="_blank"
        rel="noopener noreferrer"
        className="relative inline-flex items-center justify-between gap-x-4 rounded-md border border-transparent bg-white px-5 py-3 text-blue-500 hover:bg-blue-50 focus:outline-none focus:ring-0"
        tracker={{
          obj: "App",
          action: "Launched",
          properties: {
            source: props.launchTracker,
            listingId: props.listingId,
          },
        }}
      >
        <div className="inline-flex gap-x-4">
          <div className="h-full w-full rounded-full bg-blue-50 p-2">
            <ActionIcon className="h-6 w-6" aria-hidden="true" />
          </div>
          <span className="gap-x-1 whitespace-nowrap text-start">
            <p>{action}</p>
            <p className="text-xs text-gray-700">{description}</p>
          </span>
        </div>
        <LaunchIcon className="h-5 w-5" aria-hidden="true" />
      </Link>
    </>
  )
}
