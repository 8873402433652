"use client"

import { BaseModalStoreType } from "@store-platform/types"
import { create } from "zustand"

type EditProfilePictureModalState = {
  title: string
  aspectRatio?: number
  shape?: "rect" | "round"
  initialFile?: File
} & (
  | { outputFormat: "base64"; action: (file: string) => unknown }
  | { outputFormat: "file"; action: (file: File) => unknown }
)

export const useUploadImageModal = create<
  BaseModalStoreType<EditProfilePictureModalState>
>((set) => ({
  payload: {
    title: "",
    aspectRatio: 1,
    shape: "rect",
    outputFormat: "base64",
    action: () => null,
  },
  isOpen: false,
  open: (payload) => {
    set({ isOpen: true, payload })
  },
  onOpenChange: (isOpen: boolean) => set({ isOpen }),
}))
