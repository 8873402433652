"use client"

import { cn } from "@store-platform/utils"
import { MobileHomeFooterButton } from "./MobileHomeFooterButton"
import {
  SvgDeveloper,
  SvgLightbulb,
  SvgNavigation,
  SvgSearch,
} from "@store-platform/ui/icons"
import { usePathname } from "next/navigation"
import {
  forwardRef,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Link } from "../Link/Link"
import { Button } from "../Button/Button"
import { useAuthModal, useIsInstalled, useUserAgentOS } from "../../hooks"
import { useUser } from "@clerk/nextjs"

type NavButtons = "browse" | "search" | "foryou" | "developer"

const getDeveloperButtonProps = (
  isSignedIn: boolean,
  isDeveloper: boolean,
  openDevAccessModal: () => void,
  openAuthModal: MouseEventHandler | undefined,
) => {
  if (!isSignedIn) return { Component: Button, onClick: openAuthModal }
  if (isDeveloper) return { Component: Link, href: "/developer" }
  return { Component: Button, onClick: openDevAccessModal }
}

export const MobileHomeFooter = forwardRef<
  HTMLDivElement,
  {
    isDeveloper: boolean
    openDevAccessModal: () => void
  }
>(({ isDeveloper, openDevAccessModal }, ref) => {
  const { isSignedIn } = useUser()
  const pathname = usePathname()
  const isInstalled = useIsInstalled()
  const isiOS = useUserAgentOS()?.name === "iOS"

  const [activeButton, setActiveButton] = useState<NavButtons | null>("browse")
  useEffect(() => {
    if (pathname === "/" || pathname.startsWith("/browse")) {
      setActiveButton("browse")
    } else if (pathname.startsWith("/search")) {
      setActiveButton("search")
    } else if (pathname.startsWith("/foryou")) {
      setActiveButton("foryou")
    } else if (pathname.startsWith("/developer")) {
      setActiveButton("developer")
    } else {
      setActiveButton(null)
    }
  }, [pathname])

  const { open: openAuthModal } = useAuthModal()
  const authGuard = useMemo<
    (redirectUrl?: string) => MouseEventHandler | undefined
  >(
    () => (redirectUrl?: string) =>
      isSignedIn ? undefined : () => openAuthModal({ redirectUrl }),

    [isSignedIn, openAuthModal],
  )
  const AuthedComponent = isSignedIn ?? false ? Link : Button

  return (
    <div
      className={cn(
        "fixed bottom-0 z-50 flex w-screen min-w-full flex-row border-t-[1px] border-t-gray-100 bg-white p-2",
        { "pb-5": isInstalled && isiOS },
      )}
      ref={ref}
    >
      <MobileHomeFooterButton
        text="Browse"
        Icon={SvgNavigation}
        active={activeButton === "browse"}
        Component={Link}
        href="/"
      />
      <MobileHomeFooterButton
        text="Search"
        Icon={SvgSearch}
        active={activeButton === "search"}
        Component={Link}
        href="/search"
      />
      <MobileHomeFooterButton
        text="For You"
        Icon={SvgLightbulb}
        active={activeButton === "foryou"}
        Component={AuthedComponent}
        href="/foryou"
        onClick={authGuard("/foryou")}
      />
      <MobileHomeFooterButton
        text="Developer"
        Icon={SvgDeveloper}
        active={activeButton === "developer"}
        {...getDeveloperButtonProps(
          isSignedIn ?? false,
          isDeveloper,
          openDevAccessModal,
          authGuard("/developer"),
        )}
      />
    </div>
  )
})
