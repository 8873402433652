import { AttributeMap } from "@store-platform/types"
import { Badge } from "../Badge/Badge"
import Link from "next/link"
import { generateBrowsingURL } from "@store-platform/utils"
import React from "react"

type AttributeBadgesProps = {
  attributes: string[]
  attributeMap: AttributeMap
  type?: "primary" | "secondary"
  asLink?: boolean
}

export const AttributeBadges = ({
  attributes = [],
  attributeMap,
  type = "primary",
  asLink = false,
}: AttributeBadgesProps) => {
  const withAttributes = attributes
    .map((id) => attributeMap?.[id])
    .filter(Boolean)

  const LinkWrapper = asLink
    ? Link
    : ({ children }: { children: React.ReactNode }) => children

  return withAttributes.map((attr) => (
    <Badge
      key={attr?.slug}
      variant={type === "primary" ? "secondary" : "outline"}
      className="hover:bg-gray-100"
    >
      <LinkWrapper href={generateBrowsingURL({}, { attributes: [attr.slug] })}>
        {attr?.name}
      </LinkWrapper>
    </Badge>
  ))
}
