"use client"

import {
  DialogDescription,
  DialogTitle,
  FormFieldFileAutoSave,
  ImageCropper,
  InfoBox,
  Loader,
  ResponsiveModal,
  ResponsiveModalContent,
  ResponsiveModalTitle,
  VisuallyHidden,
} from ".."
import { useCallback, useEffect, useState } from "react"
import { useUploadImageModal } from "../../hooks"
import { useDropzone } from "react-dropzone"
import { cn } from "@store-platform/utils"

export function ImageUploadModal() {
  const [image, setImage] = useState<File | null>(null)
  const [processing, setProcessing] = useState(false)

  const { payload, isOpen, onOpenChange } = useUploadImageModal()

  const openChange = (open: boolean) => {
    onOpenChange(open)
    if (!open) setImage(null)
  }

  useEffect(() => {
    if (payload.initialFile) {
      setImage(payload.initialFile)
    }
  }, [payload.initialFile])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setImage(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <ResponsiveModal open={isOpen} onOpenChange={(open) => openChange(open)}>
      <VisuallyHidden>
        <DialogTitle>Upload Image</DialogTitle>
        <DialogDescription>
          Upload and adjust your image to fit the required dimensions
        </DialogDescription>
      </VisuallyHidden>
      <ResponsiveModalContent className="!bg-gray-50 sm:w-[600px] sm:h-[800px] sm:max-w-none p-0">
        <div
          {...getRootProps({
            // stops browser filepicker from opening when you manually click the button
            onClick: (event) => event.stopPropagation(),
            className: cn(
              "relative flex flex-col gap-2 overflow-auto justify-between rounded-lg p-6",
              isDragActive &&
                "outline-2 outline-dashed outline-gray-800 opacity-70 transition-opacity",
            ),
          })}
        >
          {isDragActive && (
            <div className="absolute flex h-[calc(100%-24px)] w-[calc(100%-24px)] justify-center items-center bg-white bg-opacity-70 z-50">
              <p className="text-black">Drop your image here</p>
            </div>
          )}
          <input {...getInputProps()} />
          <ResponsiveModalTitle className="text-left text-3xl text-gray-900 font-semibold mb-6">
            {payload.title}
          </ResponsiveModalTitle>
          {processing ? (
            <div className="m-auto h-full flex align-middle items-center">
              <Loader size={60} />
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center">
                <FormFieldFileAutoSave
                  label={
                    image ? "Upload a different image" : "Upload your new image"
                  }
                  accept={["image/*"]}
                  action={setImage}
                />
                <p className="text-gray-500 text-sm">
                  or drag and drop your image here
                </p>
              </div>
              {!image ? (
                <InfoBox message="You will be able to adjust your image after uploading it" />
              ) : (
                <ImageCropper
                  image={image}
                  shape={payload.shape}
                  aspectRatio={payload.aspectRatio}
                  outputFormat={payload.outputFormat}
                  onSubmit={async (file: File | string) => {
                    setProcessing(true)
                    await payload.action(file as string & File)
                    openChange(false)
                    setProcessing(false)
                  }}
                />
              )}
            </>
          )}
        </div>
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}
